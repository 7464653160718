import React from 'react';
import { Box, Text, Center, Button, useDisclosure, AspectRatio } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import WeddingFilter from '@/components/Section/Filter';
import Image from '@/components/Common/LazyImage';
import ImgLogo from '@/assets/icons/logo.png';

import {
  ENABLE_WEDDING_FILTER,
  DEFAULT_LANGUAGE as lang,
  ENABLE_VIDEO_ALTERNATIVE,
} from '@/constants/feature-flags';
import { COVER_GALLERY, COVER_GALLERY_2 } from '@/constants/assets';

import Content from './Content';
import txtWording from './locales';

function PhotoSection({ ...rest }) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box bgColor="bgAlternative" padding="32px" {...rest}>
      <WithAnimation top>
        <Center>
          <Image src={ImgLogo} maxWidth="110px" />
        </Center>
      </WithAnimation>
      {/* Quote Sections */}
      <WithAnimation bottom>
        <Box textAlign="center" color="secondaryColorText" fontSize="md" margin="16px 0 24px 0">
          <Text>
            “And I knew exactly how old Walt Disney’s Cinderella felt when she found her prince.”
          </Text>
          <Text margin="16px">—Elizabeth Young</Text>
        </Box>
      </WithAnimation>
      {/* Cover Section */}
      <Box>
        {ENABLE_VIDEO_ALTERNATIVE && (
          <WithAnimation>
            <AspectRatio maxW="100%" ratio={16 / 9} borderRadius="16px">
              <iframe
                src="https://www.youtube.com/embed/tuS1LuPYQ_Q"
                allowFullScreen
                loading="lazy"
                style={{ borderRadius: '16px' }}
              />
            </AspectRatio>
          </WithAnimation>
        )}
        <WithAnimation>
          <Center>
            <Image
              src={COVER_GALLERY}
              maxW={ENABLE_VIDEO_ALTERNATIVE ? '100%' : '90%'}
              borderRadius="16px"
              margin="16px 0"
            />
          </Center>
        </WithAnimation>
        <WithAnimation>
          <Center>
            <Image
              src={COVER_GALLERY_2}
              maxW={ENABLE_VIDEO_ALTERNATIVE ? '100%' : '90%'}
              borderRadius="16px"
            />
          </Center>
        </WithAnimation>
      </Box>
      {/* Button Section */}
      <WithAnimation>
        <Center>
          <Button
            bgColor="bgPrimary"
            colorScheme="whiteAlpha"
            size="sm"
            fontWeight="light"
            color="mainColorText"
            margin="16px 0 18px"
            onClick={() => onOpen()}
          >
            {txtWording.openGallery[lang]}
          </Button>
        </Center>
      </WithAnimation>
      {/* Content gallery */}
      <Content isOpen={isOpen} onClose={onClose} />
      {ENABLE_WEDDING_FILTER && <WeddingFilter />}
    </Box>
  );
}

export default React.memo(PhotoSection);
