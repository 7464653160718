import React, { useState, useEffect } from 'react';
import { func } from 'prop-types';
import { Box, Heading, Center, Image, Text } from '@chakra-ui/react';

import ScrollToDown from '@/components/Common/ScrollToDown/V1';
import WithAnimation from '@/components/Common/WithAnimation';
import CopyRight from '@/components/Section/CopyRight';

import ImgLogo from '@/assets/icons/logo.png';
import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';
import useInvitation from '@/hooks/useInvitation';

import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';
import { THE_BRIDE, WEDDING_DATE } from '@/constants';
import { BG_SECONDARY } from '@/constants/colors';
import { styCoverV6 } from './styles';

import txtWording from './locales';

/**
 * Function to render Component CoverV3
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 */
function Cover({ onSeeDetail }) {
  const [loading, setLoading] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const invitation = useInvitation();

  const handleClickDetail = () => {
    if (loading) return undefined;

    playMusic();

    setLoading(true);
    setTimeout(() => {
      onSeeDetail();
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    setShowTitle(true);
  }, [invitation]);

  return (
    <Box minHeight="100vh" backgroundColor={BG_SECONDARY} pos="relative">
      <div css={styCoverV6}>
        <Box display="block">
          {showTitle && (
            <Box position="absolute" width="100%" top="42px">
              {!invitation && (
                <Box>
                  <Text
                    align="center"
                    marginBottom="-8px"
                    fontWeight="normal"
                    fontSize="xl"
                    color="secondaryColorText"
                    padding="0 0 8px 0"
                    paddingTop="3rem"
                  >
                    Wedding Announcement
                  </Text>
                  <Center margin="1px 0 8px">
                    <Image src={ImgLogo} maxWidth="110px" />
                  </Center>
                </Box>
              )}
              {invitation && (
                <Center padding="0 0 8px 0" paddingTop="3rem">
                  <Image src={ImgLogo} maxWidth="110px" />
                </Center>
              )}
              <WithAnimation>
                <Heading
                  textAlign="center"
                  fontWeight="normal"
                  fontSize="3xl"
                  fontFamily="body"
                  marginTop="-8px"
                  color="secondaryColorText"
                >
                  {THE_BRIDE}
                </Heading>
              </WithAnimation>
              <WithAnimation>
                <Heading
                  textAlign="center"
                  fontWeight="normal"
                  fontSize="2xl"
                  fontFamily="body"
                  color="secondaryColorText"
                >
                  {WEDDING_DATE}
                </Heading>
              </WithAnimation>
            </Box>
          )}
          <Box position="absolute" display="block" width="100%" bottom="62px">
            <ScrollToDown
              text={invitation ? txtWording.open[lang] : txtWording.openAnnouncement[lang]}
              onClick={handleClickDetail}
              loading={loading}
            />
          </Box>
        </Box>
      </div>
      <CopyRight
        withWave
        withSong={false}
        withFlatIcon={false}
        transformWave="translateY(-38px)"
        transformBody="translateY(-40px)"
        fontSize="small"
        padding="16px 16px 8px 16px"
      />
    </Box>
  );
}

Cover.propTypes = {
  onSeeDetail: func.isRequired,
};

export default Cover;
