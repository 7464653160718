// import Img1 from '../images/gallery/1.jpg';
// import Img2 from '../images/gallery/1.jpg';
// import Img3 from '../images/gallery/1.jpg';

export default [
  {
    img: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/12.jpg?updatedAt=1698902016862`,
    title: 'First Meet',
    description: `Michael met Selvy when she was a new university student in 2017. At that time, he was the mentor on his senior year. He fell in love at the first sight; hence the love story begins.`,
  },
  {
    img: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/12.jpg?updatedAt=1698902016862`,
    title: 'Officially Date',
    description: `After knowing each other better, they started dating in July 2018. Through all the rains & rainbows in the past 5 years, they shape each other into a better person.`,
  },
  {
    img: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/12.jpg?updatedAt=1698902016862`,
    title: 'Engaged!',
    description: `They finally decided to marry as they share same life goals, hold onto similar values, and most importantly, they always enjoy each other’s company - even if it is just sitting without talking.`,
  },
  {
    img: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/12.jpg?updatedAt=1698902016862`,
    title: 'The Wedding Day',
    description: `Today (3.12.23), they finally tie the knot. They are no longer two, but one. Therefore, what God has joined together, let no one separate.`,
  },
];
