import React from 'react';
import { string } from 'prop-types';
import { Box, Center, Text } from '@chakra-ui/react';

import ImgLogo from '@/assets/icons/logo.png';
import ImgFlowerLeft from '@/assets/pattern/flower-left.png';

import Image from '@components/Common/LazyImage';
import WithAnimation from '@components/Common/WithAnimation';
import WeddingLocation from './Location';
import WeddingDetail from './Detail';

import useShiftTime from '@/hooks/useShiftTime';
import useInvitation from '@/hooks/useInvitation';

import {
  WEDDING_RESEPSI_DAY,
  WEDDING_AKAD_TIME,
  WEDDING_RESEPSI_DATE,
  WEDDING_DATE_DATE,
  WEDDING_AKAD_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
  WEDDING_RESEPSI_TIME,
} from '@/constants';

import { BG_DETAIL, BG_COVER } from '@/constants/assets';
import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';

import txtWording from './locales';

/**
 * function to render WeddingSectionV3 component
 * @returns {JSX.Element}
 */
function WeddingSectionV3({ color, ...rest }) {
  const time = useShiftTime();
  const invitation = useInvitation();

  return (
    <Box zIndex="10" {...rest}>
      {false && (
        <Box>
          <Image src={BG_COVER} width="100%" />
        </Box>
      )}
      <Box bgColor="bgAlternative" padding="42px 42px" bgSize="initial" zIndex="9">
        <WithAnimation>
          <Center marginBottom="12px">
            <Image src={ImgLogo} maxWidth="110px" />
          </Center>
        </WithAnimation>
        <WithAnimation>
          <Text textAlign="center" fontSize="xl" color="secondaryColorText" marginBottom="32px">
            “When we fall in love, <br />
            it will be forever”
          </Text>
        </WithAnimation>
        <Box bgColor="bgPrimary" padding="16px" borderRadius="16px">
          <Box
            bgImage={BG_DETAIL}
            bgSize="cover"
            bgColor="white"
            borderRadius="2xl"
            padding="24px 4px"
            boxShadow="inset 0 0 0 2000px rgb(255 255 255 / 60%)"
          >
            <WithAnimation>
              <Box>
                {time !== 'Reception Only' && (
                  <WeddingDetail
                    color={color}
                    title={txtWording.akad[lang]}
                    location={!invitation && false && WEDDING_AKAD_LOC_NAME}
                    locationRoad={!invitation && false && WEDDING_RESEPSI_LOC_ROAD}
                    subTitle={
                      <>
                        {WEDDING_RESEPSI_DAY}
                        <br />
                        {WEDDING_DATE_DATE}
                        <br />
                        {WEDDING_AKAD_TIME}
                      </>
                    }
                  />
                )}

                {invitation &&
                  (time === 'Holy Matrimony Only' || time === 'Holy Matrimony & Reception') && (
                    <WithAnimation>
                      <WeddingLocation color={color} enableCalendar={false} />
                    </WithAnimation>
                  )}
              </Box>
            </WithAnimation>
            {invitation && time !== 'Holy Matrimony Only' && (
              <WithAnimation>
                <WeddingDetail
                  color={color}
                  title={txtWording.reception[lang]}
                  subTitle={
                    <>
                      {WEDDING_RESEPSI_DAY}
                      <br />
                      {WEDDING_RESEPSI_DATE}
                      <br />
                      {WEDDING_RESEPSI_TIME}
                    </>
                  }
                />
              </WithAnimation>
            )}
            {invitation && (time === 'Reception Only' || time === 'Holy Matrimony & Reception') && (
              <WithAnimation>
                <WeddingLocation color={color} />
              </WithAnimation>
            )}
          </Box>
        </Box>
      </Box>
      {false && (
        <Box
          zIndex="0"
          bgColor="transparent"
          padding="42px 42px"
          bgImage={`url(${ImgFlowerLeft})`}
          bgSize="initial"
          bgPosition="bottom left"
          bgRepeat="no-repeat"
          height="190px"
        />
      )}
    </Box>
  );
}

WeddingSectionV3.propTypes = {
  color: string,
};

WeddingSectionV3.defaultProps = {
  color: 'white',
};

export default React.memo(WeddingSectionV3);
