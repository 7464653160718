import React, { useState, useCallback } from 'react';

import CoverSection from '@components/Section/Cover';
import AyatSection from '@components/Section/Ayat';
import CoupleInfoSection from '@components/Section/CoupleInfo';
import WeddingSection from '@components/Section/Wedding';
import YoutubeLive from '@components/Section/YoutubeLive';
import GiftCard from '@components/Section/GiftCard';
import PhotoGallery from '@components/Section/PhotoGallery';
import WishesSection from '@components/Section/Wishes/V2';
import FooterSection from '@components/Section/Footer';
import LoveStory from '@/components/Section/LoveStory';
import QRCard from '@components/Common/QRCard/V2';

import Layout from '@components/Layout';
import FloatingMusic from '@components/Common/FloatingMusic/Lazy';
import WithAnimation from '@components/Common/WithAnimation';
import DarkmodeNotification from '@components/Common/DarkmodeNotification';
import Navigation from '@/components/Common/Navigation';
import * as FEATURE from '../constants/feature-flags';
import * as ID from '../constants/identifier';
import useGuestInfo from '@/hooks/useGuestInfo';

import useInvitation from '@/hooks/useInvitation';
import useLang from '@/hooks/useLang';
import RSVPNonCovid from '@components/Section/RSVPNonCovid';

const ELEMENT_CONTENT_ID = 'invitato-detail-content';

export default function Home() {
  const [showDetailContent, setShowDetailContent] = useState(false);
  const invitation = useInvitation();
  const lang = useLang();
  const { code } = useGuestInfo();

  /**
   * function to show detail invitation
   * @returns {void}
   */
  const handleClickDetail = useCallback(() => {
    setShowDetailContent(true);
  }, []);

  /**
   * function to render invitation content
   * @returns {JSX.Element}
   */
  const renderContent = () => {
    if (!showDetailContent) return null;

    return (
      <div id={ELEMENT_CONTENT_ID}>
        <AyatSection />
        <CoupleInfoSection name={ID.ID_COUPLE_SECTION} lang={lang} />
        <WeddingSection name={ID.ID_DETAIL_SECTION} lang={lang} />
        {FEATURE.ENABLE_QR_INVITATION && invitation && code && (
          <QRCard lang={lang} name={ID.ID_QRCODE} />
        )}
        {FEATURE.ENABLE_LOVE_STORY && <LoveStory name={ID.ID_LOVESTORY_SECTION} lang={lang} />}
        {!FEATURE.ENABLE_COVID_PROTOCOL && invitation && (
          <RSVPNonCovid name={ID.ID_RSVP_SECTION} lang={lang} />
        )}
        <YoutubeLive lang={lang} name={ID.ID_YOUTUBE_SECTION} />
        {FEATURE.ENABLE_GIFT_CARD && <GiftCard lang={lang} name={ID.ID_GIFT_SECTION} />}
        <PhotoGallery name={ID.ID_GALLERY} lang={lang} />
        <WishesSection lang={lang} />
        <FooterSection lang={lang} />
      </div>
    );
  };

  return (
    <Layout>
      <FloatingMusic shouldShowMusicIcon={showDetailContent} />
      {FEATURE.ENABLE_DARKMODE_NOTIFICATION && <DarkmodeNotification lang={lang} />}
      {showDetailContent && <Navigation />}
      <WithAnimation top collapse when={!showDetailContent}>
        <CoverSection elementTarget={ELEMENT_CONTENT_ID} onSeeDetail={handleClickDetail} />
      </WithAnimation>
      {renderContent()}
    </Layout>
  );
}
