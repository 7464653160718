import get from '@invitato/helpers/dist/getLocale';

export default {
  dear: get('Kepada Bapak/Ibu/Saudara/i,', 'Dear Mr/Mrs/Ms,'),
  guest: get('Keluarga & Teman-teman', 'Family & Friends'),
  openingAnnuncementGeneral: get(
    'Dengan memohon anugerah dan berkat Tuhan, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:',
    'Together with joyful hearts and the grace of God, we joyfully announce the upcoming marriage of:',
  ),
  openingAnnuncementMuslim: get(
    `Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:`,
    `Together with the blessing of Allah Subhanahu wa Ta'ala, we joyfully announce the upcoming marriage of:`,
  ),
  openingInvitationGeneral: get(
    'Dengan memohon anugerah dan berkat Tuhan, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:',
    'Together with our joyful hearts and by the blessing of the Lord, we cordially request the honor of your presence at the wedding of:',
  ),
  openingInvitationMuslim: get(
    `Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:`,
    `Together with the blessing of Allah Subhanahu wa Ta'ala, we cordially request the honor of your presence at the wedding celebration of:`,
  ),
};
