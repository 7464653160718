// import P1 from '@/images/gallery/1.jpg';
// import P2 from '@/images/gallery/2.jpg';
// import P3 from '@/images/gallery/3.jpg';
// import P4 from '@/images/gallery/4.jpg';
// import P5 from '@/images/gallery/5.jpg';
// import P6 from '@/images/gallery/6.jpg';
// import P7 from '@/images/gallery/7.jpg';
// import P8 from '@/images/gallery/8.jpg';
// import P9 from '@/images/gallery/9.jpg';
// import P10 from '@/images/gallery/10.jpg';
// import P11 from '@/images/gallery/11.jpg';
// import P12 from '@/images/gallery/12.jpg';
// import P13 from '@/images/gallery/13.jpg';
// import P14 from '@/images/gallery/14.jpg';
// import P15 from '@/images/gallery/15.jpg';
// import P16 from '@/images/gallery/16.jpg';
// import P17 from '@/images/gallery/17.jpg';
// import P18 from '@/images/gallery/18.jpg';
// import P19 from '@/images/gallery/19.jpg';
// import P20 from '@/images/gallery/20.jpg';
// import P21 from '@/images/gallery/21.jpg';
// import P22 from '@/images/gallery/22.jpg';
// import P23 from '@/images/gallery/23.jpg';
// import P24 from '@/images/gallery/24.jpg';
// import P25 from '@/images/gallery/25.jpg';
// import P26 from '@/images/gallery/26.jpg';
// import P27 from '@/images/gallery/27.jpg';
// import P28 from '@/images/gallery/28.jpg';
// import P29 from '@/images/gallery/29.jpg';
// import P30 from '@/images/gallery/30.jpg';
// import P31 from '@/images/gallery/31.jpg';
// import P32 from '@/images/gallery/32.jpg';

export const photos = [
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/1.jpg?updatedAt=1698902014232`,
    // thumbnail: P1,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/2.jpg?updatedAt=1698902012132`,
    // thumbnail: P2,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/3.jpg?updatedAt=1698902013983`,
    // thumbnail: P3,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/4.jpg?updatedAt=1698902011819`,
    // thumbnail: P4,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/5.jpg?updatedAt=1698902014194`,
    // thumbnail: P5,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/6.jpg?updatedAt=1698902014617`,
    // thumbnail: P6,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/7.jpg?updatedAt=1698902012570`,
    // thumbnail: P7,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/8.jpg?updatedAt=1698902014171`,
    // thumbnail: P8,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/9.jpg?updatedAt=1698902014197`,
    // thumbnail: P9,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/10.jpg?updatedAt=1698902014083`,
    // thumbnail: P10,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/11.jpg?updatedAt=1698902016637`,
    // thumbnail: P11,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/12.jpg?updatedAt=1698902016862`,
    // thumbnail: P12,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/13.jpg?updatedAt=1698902017165`,
    // thumbnail: P13,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/14.jpg?updatedAt=1698902018391`,
    // thumbnail: P14,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/15.jpg?updatedAt=1698902019291`,
    // thumbnail: P15,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/16.jpg?updatedAt=1698902020018`,
    // thumbnail: P16,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/17.jpg?updatedAt=1698902019662`,
    // thumbnail: P17,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/18.jpg?updatedAt=1698902020672`,
    // thumbnail: P18,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/19.jpg?updatedAt=1698902019950`,
    // thumbnail: P19,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/20.jpg?updatedAt=1698902020850`,
    // thumbnail: P20,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/21.jpg?updatedAt=1698902021199`,
    // thumbnail: P21,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/22.jpg?updatedAt=1698902021984`,
    // thumbnail: P22,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/23.jpg?updatedAt=1698902022666`,
    // thumbnail: P23,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/24.jpg?updatedAt=1698902023020`,
    // thumbnail: P24,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/25.jpg?updatedAt=1698902023625`,
    // thumbnail: P25,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/26.jpg?updatedAt=1698902024318`,
    // thumbnail: P26,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/27.jpg?updatedAt=1698902024870`,
    // thumbnail: P27,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/28.jpg?updatedAt=1698902025319`,
    // thumbnail: P28,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/29.jpg?updatedAt=1698902026781`,
    // thumbnail: P29,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/30.jpg?updatedAt=1698902026842`,
    // thumbnail: P30,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/31.jpg?updatedAt=1698902027266`,
    // thumbnail: P31,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/32.jpg?updatedAt=1698902028877`,
    // thumbnail: P32,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/33.jpg?updatedAt=1698902029708`,
    // thumbnail: P33,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/34.jpg?updatedAt=1698902029102`,
    // thumbnail: P34,
  },
  {
    original: `https://ik.imagekit.io/adelinejune/michaelselvy/gallery/35.jpg?updatedAt=1698902029913`,
    // thumbnail: P35,
  },
];
